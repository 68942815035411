import Vue from "vue";
import Router from "vue-router";
import Home from "./pages/Home.vue";
// import Vip from "./pages/Vip.vue";
import Reports from "./pages/Reports.vue";
// import Login from "./pages/Login.vue";
// import Register from "./pages/Register.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        title: "99手游加速器_永久免费游戏加速器",
        keywords: "",
        description:
          "99手游加速器是一款完全免费的游戏加速器，采用专线加速，加速效果稳定，支持海内外游戏加速！完美解决网游用户在游戏过程中遇到的高延迟、掉线丢包，无法登录，连接错误等问题。",
      },
    },
    // {
    //   path: "/vip",
    //   name: "vip",
    //   component: Vip,
    //   meta: {
    //     title: "99加速器-VIP中心",
    //   },
    // },
    {
      path: "/reports",
      name: "reports",
      component: Reports,
      meta: {
        title: "联系我们_99手游加速器",
      },
    },
    // {
    //   path: "/login",
    //   name: "login",
    //   component: Login,
    //   meta: {
    //     title: "99加速器-会员登录",
    //   },
    // },
    // {
    //   path: "/register",
    //   name: "register",
    //   component: Register,
    //   meta: {
    //     title: "99加速器-注册会员",
    //   },
    // },
  ],
});
