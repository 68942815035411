<template>
  <footer class="footer">
    <slot name="bg"></slot>
    <slot name="ba-copy"></slot>
  </footer>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created () {},
  mounted () {}
}
</script>
<style scoped>
</style>