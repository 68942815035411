<template>
  <div class="banner">
    <img src="https://cdn.ourplay.net/opweb/1605930110146977.png" @load="coordsHandler" usemap="#bannermap" alt="">
    <map ref="bannermap" name="bannermap" id="bannermap">
      <area shape="rect" coords="0,0,0,0" href="https://sj.qq.com/appdetail/com.excean.na" target="_blank" />
    </map>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {
    coordsHandler() {
      let bannerMapElem = document.querySelector('#bannermap')
      let imgElem = document.querySelector('.banner')
      let imgWidth = imgElem.offsetWidth
      let imgHeight = imgElem.offsetHeight
      bannerMapElem.areas[0].coords = `${parseInt(imgWidth*0.128)},${parseInt(imgHeight*0.765)},${parseInt(imgWidth*0.392)},${parseInt(imgHeight*0.918)}`
    }
  },
  created () {
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.coordsHandler()
    })
  }
}
</script>
<style scoped>
.banner {
  width: 100%;
  /* padding-bottom: 42%; */
  position: relative;
}
.banner img {
  width: 100%;
}
</style>