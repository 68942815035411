<template>
  <div id="app" :style="{minHeight: innerHeight + 'px'}">
    <za-layout>
      <template v-slot:view>  
        <router-view v-wechat-title="$route.meta.title"/>
      </template>
    </za-layout>
  </div>
</template>

<script>
import zaLayout  from '@/components/Layout.vue'

export default {
  name: 'app',
  components: {
    zaLayout
  },
  data () {
    return {
      innerHeight: window.innerHeight
    }
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: no-repeat 0 0;
  background-size: 100% auto;
  font-size: inherit;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  line-height: inherit;
}
li {
  list-style: none;
}
a {
  cursor: pointer;
}
input,button {
  outline: none;
  border: none;  
}
button {
  cursor: pointer;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  min-width: 1200px;
  background: #fff;
  color: #333;
}
</style>
