<template>
  <section class="home">
    <za-banner></za-banner>
    <div class="wrap">
      <p>
        99手游加速器，一款专业、免费的游戏加速器，更稳定、更流畅！一键解决手游因网络不佳造成的游戏卡顿、延迟、掉线、加载缓慢等一系列问题！
      </p>
      <h2 class="linkus">超低延迟 极速稳定</h2>
      <div class="desc">
        <p>
          稳定低延迟，流畅不掉线，自动选择最佳的加速线路，支持超长时间的游戏加速状态；WIFI和4G网络智能切换，大幅降低游戏延迟、掉线、丢包等问题，强力保障您游戏的流畅稳定！
        </p>
      </div>
      <h2 class="linkus">海量手游 一键免费加速</h2>
      <div class="desc">
        <p>
          免费支持LOL手游、吃鸡、王者荣耀、云顶手游、方舟：生存进化、Sky光遇、剑灵：革命、城堡称霸、文明重启、荒野乱斗、七大罪、光与暗之交战、游戏王、狂野飙车9等数千款热门手游一键加速！为玩家提供稳定的游戏网络环境，极速畅玩海量手游！
        </p>
      </div>
      <h2 class="linkus">网络优化 极速稳定</h2>
      <div class="desc">
        <p>
          自动识别本地游戏，一键享受加速服务。稳定低延迟，告别网络束缚，同时支持超长时间游戏加速状态，持久稳定不掉线！
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import zaBanner from "@/components/Banner.vue";
export default {
  components: {
    zaBanner,
  },
  metaInfo: {
    title: "99手游加速器_永久免费游戏加速器",
    meta: [
      {
        name: "Keywords",
        content: "",
      },
      {
        name: "description",
        content:
          "99手游加速器是一款完全免费的游戏加速器，采用专线加速，加速效果稳定，支持海内外游戏加速！完美解决网游用户在游戏过程中遇到的高延迟、掉线丢包，无法登录，连接错误等问题。",
      },
    ],
  },
  data() {
    return {
      reportsList: [
        {
          title: "中国网络文学版权保护研究报告",
          desc:
            "基于中国网络文学正版化进程迈入发展新阶段的时代背景，在梳理中国网络文学行业发展脉络的同时，以行业整体视角回顾......",
        },
        {
          title: "移动互联网虚拟化技术发展报告",
          desc:
            "基于移动互联网虚拟化技术与PC端联合对比，跟踪虚拟化技术的发展趋势与历史沿革，从横纵向的角度，梳理了虚拟化技术......",
        },
        {
          title: "中国互动娱乐季度数据发布研究报告",
          desc:
            "基于多年游戏行业研究、数据积累以及市面产品监测成果，通过对娱乐应用类型、题材、IP等各个因素的分析，从技术、市.......",
        },
        {
          title: "中国移动营销行业洞察报告",
          desc:
            "按照营销产业链的维度划分，参与营销的企业主要有广告主、营销供应商、媒体三大类型。广告主作为产业链的开端和需求......",
        },
      ],
    };
  },
};
</script>
<style scoped>
.article {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0 130px;
}
.article-title {
  font-size: 36px;
  text-align: center;
}
.article-inner {
  padding: 65px 88px;
  font-size: 18px;
  overflow: auto;
}
.article-item {
  width: 202px;
  float: left;
  margin-right: 72px;
}
.article-item:nth-child(4n) {
  margin-right: 0;
}
.article-item-cover {
  width: 202px;
  height: 160px;
  padding: 12px;
  color: rgba(255, 255, 255, 0.7);
  background-image: url("../assets/cardBg.png");
}
.article-item-title {
  width: 180px;
  margin: 20px auto;
  text-align: center;
  color: #000;
}
.article-item-desc {
  font-size: 14px;
  line-height: 30px;
  color: #333;
}
.more-link {
  text-align: center;
}
.more-link a {
  font-size: 18px;
  height: 27px;
  line-height: 27px;
  display: inline-block;
  color: #016091;
  padding-bottom: 1px;
}
.more-link a:hover {
  border-bottom: 1px solid #016091;
}
.more-link a span {
  font-weight: 700;
}
.wrap {
  width: 1020px;
  margin: 0 auto;
  padding: 20px;
}
.linkus {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin: 30px 0;
}
.desc {
  font-size: 18px;
  color: #333;
}
</style>
