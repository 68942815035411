import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueWechatTitle from "vue-wechat-title";
import Meta from "vue-meta";
Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

Vue.use(VueWechatTitle);
new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
