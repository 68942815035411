<template>
  <section class="reports">
    <za-banner></za-banner>
    <div class="wrap">
      <div class="linkus">联系我们</div>
      <!-- <div class="desc">
        地址：上海市闵行区田林路1036号科技绿洲三期16号楼4楼
      </div> -->
      <div class="desc">
        地址：上海市嘉定区真南路4268号2幢J2403室
      </div>
      <div class="desc">联系电话：021-64480220</div>
      <div class="desc">联系邮箱：support@excean.com</div>
      <!-- <img :src="img" alt="" class="img"> -->
    </div>
  </section>
</template>

<script>
import zaBanner from "@/components/Banner.vue";
export default {
  components: {
    zaBanner,
  },
  metaInfo: {
    title: "联系我们_99手游加速器",
    meta: [
      {
        name: "Keywords",
        content: "",
      },
      {
        name: "description",
        content: "",
      },
    ],
  },
  data() {
    return {
      reportsList: [
        {
          title: "中国网络文学版权保护研究报告",
          desc:
            "基于中国网络文学正版化进程迈入发展新阶段的时代背景，在梳理中国网络文学行业发展脉络的同时，以行业整体视角回顾中国网络文学正版化进程的发展概况，以及网络文学侵权盗版模式的变化变异现状。基于上述内容，报告指出了中国网络文学版权保护现状面临的部分问题及发展趋势，并给出了针对性的建议意见。",
        },
        {
          title: "移动互联网虚拟化技术发展报告",
          desc:
            "基于移动互联网虚拟化技术与PC端联合对比，跟踪虚拟化技术的发展趋势与历史沿革，从横纵向的角度，梳理了虚拟化技术的发展，并对未来虚拟化技术的研究方向进行展望，从技术应用、落地等角度，希望为相关的技术研究者与企业提供参考意见。",
        },
        {
          title: "中国互动娱乐季度数据发布研究报告",
          desc:
            "基于多年游戏行业研究、数据积累以及市面产品监测成果，通过对娱乐应用类型、题材、IP等各个因素的分析，从技术、市场、宣发等多个角度切实反映游戏行业现状与未来发展趋势。希望能为相关企业与资本市场提供参考意见与运营建议。",
        },
        {
          title: "中国移动营销行业洞察报告",
          desc:
            "按照营销产业链的维度划分，参与营销的企业主要有广告主、营销供应商、媒体三大类型。广告主作为产业链的开端和需求释放端，在产业链当中起到了关键性作用，营销供应商则作为服务方，通过服务于产业链两端，来提升竞争力，媒体是输出窗口，负责传递广告主想要传播的品牌信息。",
        },
      ],
      hotTopic: [
        {
          title: "中国网络文学版权保护研究报告",
          path: "/login",
        },
        {
          title: "移动互联网虚拟化技术发展报告",
          path: "/login",
        },
        {
          title: "中国互动娱乐季度数据发布研究报告",
          path: "/login",
        },
      ],
      img: require("../assets/address.png"),
    };
  },
  methods: {},
};
</script>
<style scoped>
.article {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}
.article-title {
  font-size: 36px;
  text-align: center;
}
.article-inner {
  padding: 65px 50px;
  overflow: auto;
}
.article-left {
  float: left;
}
.article-item {
  width: 680px;
  overflow: hidden;
  margin: 0 86px 44px 0;
}
.article-item:nth-child(4n) {
  margin-right: 0;
}
.article-item-cover {
  float: left;
  width: 202px;
  height: 160px;
  padding: 12px;
  margin-right: 20px;
  color: rgba(255, 255, 255, 0.7);
  background-image: url("../assets/cardBg.png");
  font-size: 18px;
}
.article-item-title {
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 16px;
}
.article-item-desc {
  overflow: hidden;
  color: #333;
}
.article-right {
  width: 300px;
  overflow: hidden;
}
.article-right > h4 {
  color: #000;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 16px;
}
.article-right li {
  color: #016091;
  line-height: 30px;
}
.article-right li:hover {
  text-decoration: underline;
}
.wrap {
  width: 1020px;
  margin: 0 auto;
  padding: 20px;
}
.img {
  margin: 20px;
  width: 540px;
  margin-bottom: 60px;
}
.linkus {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin: 30px 0;
}
.desc {
  margin-bottom: 12px;
  color: #333;
}
</style>
