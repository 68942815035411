<template>
  <section class="layout" :style="{ minHeight: overHeight + 'px' }">
    <div class="layout-top">
      <mul-head class="layout-header">
        <template v-slot:logo>
          <div class="layout-header-logo">
            <router-link to="/">
              <img src="../assets/yqlogo.png" />
            </router-link>
          </div>
        </template>
        <template v-slot:nav>
          <mul-nav class="layout-header-nav" :navList="navList" />
        </template>
      </mul-head>
    </div>
    <div class="layout-view">
      <slot name="view"></slot>
    </div>
    <div class="layout-bot">
      <mul-foot class="layout-footer">
        <div slot="ba-copy" class="layout-footer-copy">
          <a class="copy" href="http://beian.miit.gov.cn/">
            <!-- 版权所有&copy;上海芃荟网络科技有限公司
            &nbsp;&copy;沪ICP备2021011317号-2 -->
            版权所有&copy;上海卓安信息科技有限公司 
            &nbsp;&copy;沪ICP备17010969号-5
          </a>
        </div>
      </mul-foot>
    </div>
  </section>
</template>

<script>
import mulHead from "@/components/Head.vue";
import mulNav from "@/components/Nav.vue";
import mulFoot from "@/components/Foot.vue";
export default {
  components: {
    mulHead,
    mulNav,
    mulFoot,
  },
  props: {},
  data() {
    return {
      overHeight: window.innerHeight,
      navList: [
        {
          path: "/",
          name: "home",
          text: "首页",
        },
        {
          path: "/reports",
          name: "reports",
          text: "联系我们",
        },
        //  {
        //   path: '/vip',
        //   name: 'vip',
        //   text: '会员权益'
        // }, {
        //   path: '/login',
        //   name: 'login',
        //   text: '会员登录'
        // }
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
.layout {
  position: relative;
}
.layout-top {
  width: 100%;
}
.layout-header {
  height: 80px;
  padding: 22px 88px 23px;
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.layout-header-logo {
  float: left;
  line-height: 36px;
}
.layout-header-logo img {
  height: 35px;
  float: left;
}
.layout-header-logo span {
  float: left;
  color: #fff;
  font-size: 24px;
  margin-left: 20px;
  font-weight: 500;
}
.layout-header-nav {
  float: right;
}
.layout-bot {
  width: 100%;
}
.layout-footer-copy {
  width: 100%;
  height: 50px;
  position: absolute;
  background-color: #2c2d33;
  color: rgba(255, 255, 255);
  text-align: center;
  line-height: 50px;
  font-size: 14px;
}
.layout-view {
  /* width: 1200px; */
  margin: 0 auto;
}
.layout-bot {
  height: 50px;
}
.copy {
  color: white;
}
</style>
