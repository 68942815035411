<template>
  <nav class="nav">
    <ul class="nav-menu">
      <router-link 
        v-for="(item, index) in navList" 
        :key="index" 
        :to="item.path">
        <li class="nav-menu-item">
          {{item.text}}
        </li>
      </router-link>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    navList: Array
  }
}
</script>
<style scoped>
.nav-menu-item {
  color: rgba(51, 51, 51, .7);
}
.nav-menu-item {
  float: left;
  font-size: 18px;
  font-weight: bolder;
  line-height: 36px;
  margin-left: 60px;
  cursor: pointer;
  transition: all .2s linear;
}
.nav-menu-item:hover,
.router-link-exact-active .nav-menu-item{
  color: #016091;
}
</style>